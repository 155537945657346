// src/components/ModelNotes.js

import React from 'react';
import './ClusterModelNotes.css'; // Create a CSS file for styling if needed

function ModelNotes() {
  return (
    <div className="model-notes">
      <h1>Model Notes</h1>
      <section>
        <h2>Version</h2>
        <p>1.0</p>
		<p>Release Date 08/08/2024</p>
      </section>
	  <section>
		<h2>Data</h2>
		<p>The data used to create this models is all compiled from the 2023-2024 NHL regular season. The teams and contracts for players are up to date as of 08/06/2024. All of the data to make this clustering algorithm is from
			<a href='https://capwages.com/' target='blank' rel="noopener noreferrer"> capwages.com</a> and all rights of the data belongs to them. It is my aim to update this data around twice a month during the season to understand the groups of teams as the season goes on.
			All of the data is on a team level is averaged out based off of all the players and goalies that registered a single stat throughout the season for that team.
		</p>
	  </section>
      <section>
        <h2>Model Type</h2>
        <p>K-means Clustering</p>
		<p></p>
      </section>
      <section>
        <h2>How It Works</h2>
        <p>Kmeans clustering aims to group like things into groups based off measurable characteristics of those things. 
		   In this case are things are NHL teams and the characterstics you have the option of grouping them off of are the variables 
		   with checkboxes.
		</p>
      </section>
	  <section>
		<h2>Silhoutte Scoring</h2>
		<p>The silhoutte score displayed above the graph is a way of scoring the quality of the clusters in the kmeans clustering method, you, the user created.
		   It is a measure of how close a team is to the other teams in its' cluster compared to the other clusters. In simpler terms it is a measure of how defined
		   each cluster is. I will spare you the math and let you know the higher the score the better, thus the more distinct each cluster is. The current
		   challenge with this dataset and NHL teams in general is while no two teams are the same, they are not ~that~ different. There aren't teams where the average age is 36 and others
		   where their average age is 22. There is difference in these variables between the teans, but it is often not terribly drastic. In the future I would like to bring
		   more data into this model that does differentiate more from team to team.
		</p>
	  </section>

      <section>
        <h2>Interpreting the graph</h2>
        <p> While this scatter plot may seem simple to interpret, I want to highlight the fact that if you have chosen more than 2 variables
			for your cluster analysis, the graph will not capture the orientation of the clusters per all of those variables, but instead will show the clusters
			correctly from the color standpoint for all chosen variables, but the orientation of each team will only be based of what you chose for the Y and Y axis variables.
			A future implementation to better capture this full picture will be using principle component analysis which combines all the used variables into just two.
		</p>
		</section>

		<section>
		<h2>Interpreting the Table</h2>
		<p>
			The table is extremely useful in understanding the clusters and what distinguishes these teams. The data for each team is the average for
			each variable based off of all their players. You can think of this as almost "the average player for that team". Then the data in this table is the 
			average of each cluster from that data. In the image below you can see cluster 1 is the blue group of teams to the lower left of the center. If you then 
			direct your attention to the table below you will see that we get the average age of that cluster and the average goals per that cluster, in addition the amount
			of teams in each cluster will also be displayed. You can now use this table to understand the graph and the differences between each team in the league better too.
			For example as a fan of the Sharks for example, you may notice your team is in group 1 and that group 3 is all playoff teams, you can that contrast the differences between group 1 and 3 in the
			table and have a better understanding of where your team needs to get to in order to be a contender.
		</p>
		<img src="/images/cluster_example.png" alt="Clusters Example" className="scatter-image" />
		</section>
		
    </div>
  );
}

export default ModelNotes;
