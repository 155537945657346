// src/components/Article.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './Article.css';

function Article() {
  const { articleId } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    import(`../articles/${articleId}.md`)
      .then(res => fetch(res.default))
      .then(res => res.text())
      .then(text => setContent(text));
  }, [articleId]);

  return (
    <div className="article">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
    </div>
  );
}

export default Article;
