// src/components/ModelNotes.js

import React from 'react';
import './ModelNotes.css'; // Create a CSS file for styling if needed

function ModelNotes() {
  return (
    <div className="model-notes">
      <h1>Model Notes</h1>
      <section>
        <h2>Version</h2>
        <p>1.0</p>
		<p>Release Date 07/11/2024</p>
      </section>
	  <section>
		<h2>Data</h2>
		<p>The data used to create these models is all compiled from the 2023-2024 NHL regular season. Players statistical data is derived from 
			<a href='https://moneypuck.com/' target='blank' rel="noopener noreferrer"> MoneyPuck.com</a>. Players contract and age data is derived from 
			<a href='https://www.capfriendly.com/' target='blank' rel="noopener noreferrer"> capfriendly.com</a>. The contract figures used in this 
			application are from the current contracts players were under in the 2023-2024 season. Contracts that were signed after to July, 1st 2024 
			are not used and any extensions that differ in cap hit for the 2024-2025 season were also not used.</p>
	  </section>
      <section>
        <h2>Model Type</h2>
        <p>For forwards, defensemen, and goalies a linear regression model is used.</p>
		<p></p>
      </section>
      <section>
        <h2>How It Works</h2>
        <p>The model(s) are trained off of the 2023-2024 player data for all of the input statistics used relative to the position you select. 
			For the sake of simplicity it assigning a value that essentially each of those is worth (i.e.) 1 goal is worth 20,000. 
			This becomes a factor used in your in your equation. so in this example our equation would like: Cap Hit = intercept value + (Goal Amount) * 20,000.
			As we add more input values (stats such as assists, hits, etc) those get appended onto the equation with their own calculated factor value</p>
      </section>
      <section>
        <h2>Model Deficencies</h2>
        <p>Where this current model struggles is with a players age. Particulary on the two ends of the age curve. The model does not know that for example Connor Bedard
			is still on his ELC and can only be making the league maximum for a entry level deal. While this will predict a large error based off his statistics, in some sense
			this is actually still very useful and we actually just projected what he was worth off based off his statistics compared to what we know his actual cap hit was.
			When looking at the other end of the spectrum of players who are very old the model favors them and their old age. That is due to the relationship between age 
			and cap hit in the NHL being very postitive (the older players generally make more), but there comes a point in many players careers (like Corey Perry currently)
			Where they are signing very cheap deals at the tail end of their career and my model fails to capture that. You can see more on the relationship between age and cap hit depicted in the scatterplot below:
		</p>
		<img src="/images/age_cap_hit.png" alt="Age vs. Cap Hit Scatterplot" className="scatter-image" />
		<p>
			Initially focusing on the blue trendline it is visibile to see that sharp rise in cap hit from young players as they leave their ELC.
			Now when we get to the other tail end of the chart we do not entirely see the same degree of trend in a fall off, but it is noticable in the points
			themselves that the higher cap hits are falling off and leaving more low cap hit points. This is also shown through the light grey confidence interval.
			A confidence interval is essentially a range that we expect the real points to fall in. In this chart when players age gets significantly higher that range
			starts growing which alludes to the fact that while cap hit may generally grow with age like our trendline states, as the confidence interval grows along with age,
			we are less confident in that statement. Where we see these wider intervals is where you will notice these model deficencies.
		</p>		
      </section>
    </div>
  );
}

export default ModelNotes;
