// src/components/Feedback.js
import React from 'react';
import './Feedback.css';

function Feedback() {
  return (
    <div className="feedback">
      <h2>Feedback</h2>
      <p>
        At The Hockey Science we value your feedback and want to make this as interactive of a community as possible!
		Please use the following forms to provide us with recommendations, report any issues, or share any feedback you have.
      </p>
      <div className="feedback-links">
		<h2>Links:</h2>
        <h5><a href="https://docs.google.com/forms/d/e/1FAIpQLSc1O-V315ilkhYnppfGKO1YqsQ7FxpMqhlb-eVSP_n1pXvItA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Machine Learning/Application Recommendations</a></h5>
        <h5><a href="https://docs.google.com/forms/d/e/1FAIpQLSecM1Wp2DBQl3BbKPLDbLKPpNbIGy5qEdPdvJMHjYrg08s-fA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Article Recommendations</a></h5>
        <h5><a href="https://docs.google.com/forms/d/e/1FAIpQLSf87Jd9aW1wT__gF8AL3jRZ9onAyAowx3xUiRp09WGzMUmFSw/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Recommendations/Data Errors</a></h5>
      </div>
    </div>
  );
}

export default Feedback;
