// src/components/CapHitPredictor.js
import React from 'react';
import './Clusters.css';
import { Link } from 'react-router-dom'; // Import Link for React Router

function Clusters() {
  return (
    <div className="clusters">
      <h2>NHL Team Clusters</h2>
	  <div className="model-notes">
        <Link to="/cluster-model-notes">Model Notes</Link>
      </div>
      <iframe
        src="https://thehockeyscience.shinyapps.io/NHL_clusters/"
        title="Clusters"
        width="100%"
        height="700px" // Adjust height as needed
        style={{ border: 'none' }}
      ></iframe>
	    <div className="credits">
        Data sourced from <a href="https://capwages.com/" target="_blank" rel="noopener noreferrer">CapWages</a>
      </div>
    </div>
  );
}

export default Clusters;