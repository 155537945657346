// src/components/TermsOfService.js

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './TermsOfService.css';

function TermsOfService() {
  const [content, setContent] = useState('');

  useEffect(() => {
    import('../articles/tos.md')
      .then(res => fetch(res.default))
      .then(res => res.text())
      .then(text => setContent(text));
  }, []);

  return (
    <div className="terms-of-service">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
    </div>
  );
}

export default TermsOfService;
