// src/components/Home.js
import React from 'react';
import Slider from 'react-slick';
import './Home.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import './ArticlePreview.css'; // Ensure this CSS file is imported

const articles = [
  {
	title: 'About',
	image: '/images/thsbanner.png',
    link: '/articles/about'
  },
  {
	title: 'Goalie Peaks and Primes',
	image: '/images/peak_gaa_filtered.png',
	link: '/articles/state-of-goalies'
  },
  {
	title: 'Evolution of the Goalies Workload',
	image: '/images/goalie_evolution.png',
	link: '/articles/goalie-evolution'
  }
  // Add more dummy articles as needed
];

function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay speed in milliseconds (3 seconds)
  };

  return (
    <div className="home">
      <h2>Featured Articles</h2>
      <Slider {...settings}>
        {articles.map((article, index) => (
          <div key={index} className="article-preview">
            <Link to={article.link} className="article-link">
			  <h3>{article.title}</h3>
              <img src={article.image} alt={article.title} />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Home;
