// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <hr className="footer-line" />
      <Link to="/terms-of-service" className="footer-link">
        Terms of Service
      </Link>
      <Link to="/privacy-policy" className="footer-link">
        Privacy Policy
      </Link>
    </div>
  );
}

export default Footer;
