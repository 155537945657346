import React from 'react';
import './Support.css';

function Support() {
  return (
    <div className="support">
      <h2>Support</h2>
      <p>
		The Hockey Science is currently a completely self funded project. If you enjoy this content and are capable of supporting the initiative, a link is provided below.
		All proceeds will be going into keeping the webserver running, and to add monthly usage time onto our shiny applications that run the predictive models which will allow you 
		and other users more time to interact and use them, thank you for your support!
      </p>
      <a
        href="https://buymeacoffee.com/thehockeyscience" 
        target="_blank" 
        rel="noopener noreferrer"
        className="coffee-link"
      >
        Buy Me a Coffee
      </a>
    </div>
  );
}

export default Support;
