// src/components/Articles.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Articles.css';

// Define your articles data here
const articles = [
  {
	title: 'About',
	image: '/images/thsbanner.png',
	link: '/articles/about'
  },
  {
	title: 'Goalie Peaks and Primes',
	image: '/images/peak_gaa_filtered.png',
	link: '/articles/state-of-goalies'
  },
  {
	title: 'Evolution of the Goalies Workload',
	image: '/images/goalie_evolution.png',
	link: '/articles/goalie-evolution'
  },
  {
	title: '2024 Free Agency Overview',
	image: '/images/ufas.png',
	link: '/articles/fa-roundup'
  },
//   {
//     title: 'The Physical Traits of NHL Teams from the 2023-2024 Season',
//     image: '/images/clusters.png',
//     link: '/articles/clusters'
//   },
  {
  title: 'Which NHL Teams got the Most and Least Out of Their Players',
  image: '/images/team_value_chart.png',
  link: '/articles/cap-roundup'
  }
  // Add more articles as needed
];

function Articles() {
  return (
    <div className="articles">
      <h2>Articles</h2>
      <div className="articles-list">
        {articles.map((article, index) => (
          <div key={index} className="article-item">
            <Link to={article.link} className="article-link">
			  <h3 className="article-title">{article.title}</h3>
              <img src={article.image} alt={article.title} className="article-image" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articles;
